<template>
  <div class="reward" v-touch:swipe.right="onSwipeRight">
      <div class="back" @click="onSwipeRight">
        <img src="../assets/img/home/back.png" alt="">
      </div>
      <img :src="img" alt=""  ondragstart="return false;">
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
// import * as d3 from 'd3';
export default {
  data() {
    return {
      title: '',
      user: '',
      level: '',
      img: '',
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('student'));
    this.level = this.$route.params.level;
    this.upgrade();
    console.log(this.user);
  },
  mounted() {
    // js
  },
  methods: {
    onSwipeRight() {
      this.$router.go(-1);
    },
    upgrade() {
      const that = this;
      this.$server.fetch('/api/study/report/upgrade', {
        student_id: this.user.student_id,
        level_name: this.level,
      }).then((res) => {
        // eslint-disable-next-line no-empty
        if (res.code === 200) {
          that.img = res.data;
        }
      });
    },
    onSwipeLeft() {
      this.$router.push({
        name: 'CurriculumStructure',
      });
    },
    gotoPage(level) {
      this.$router.push({
        name: 'Reward',
        params: {
          level,
        },
      });
    },
  },
};
</script>
<style lang="less">
.reward{
    width: 100vw;
    height: 100vh;
    img{
        width: 100%;
    }
    .level{
        position: absolute;
        width: 8vw;

    }
    .l1{
        left: 13vw;
        top: 24.8vw;
        height: 44vw;
    }
    .l2{
        left: 21.2vw;
        top: 24.8vw;
        height: 44vw;
    }
    .l3{
        left: 29.3vw;
        top: 23.2vw;
        height: 45.4vw;
    }
}
</style>
